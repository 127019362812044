import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar"; // Make sure to create this component
import CoinbaseAuthComponent from "./components/useCoinbaseOAuth";
import PnLDisplay from "./components/PnLDisplay";
import Login from "./components/Login";
import RegistrationForm from "./components/Registration";
import Dashboard from "./components/Dashboard";
import Card from "./components/card";
import ConnectCoinbase from "./components/ConnectCoinbase";
import ProtectedRoute from "./components/ProtectedRoute";

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<PnLDisplay />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegistrationForm />} />
            <Route
              path="/connect/coinbase"
              element={
                <ProtectedRoute>
                  <ConnectCoinbase />
                </ProtectedRoute>
              }
            />
            <Route path="/card" element={<Card />} />
            <Route path="/callback" element={<CoinbaseAuthComponent />} />
          </Routes>
        </main>
        <footer className="bg-gray-800 text-white p-4 text-center">
          <p>&copy; 2024 Portfolio PnL. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
