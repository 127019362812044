// Card.js
import React from "react";

const Card = ({ title, titleClassName, children }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className={`text-xl font-bold ${titleClassName}`}>{title}</h2>
      <div className="mt-4">{children}</div>
    </div>
  );
};

export default Card;
