import React, { useEffect, useState, useRef, useCallback } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { enUS } from "date-fns/locale";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LiveChart = ({ symbol }) => {
  const [priceData, setPriceData] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [volume24h, setVolume24h] = useState(null);
  const [low24h, setLow24h] = useState(null);
  const [high24h, setHigh24h] = useState(null);
  const [priceChange24h, setPriceChange24h] = useState(null);
  const ws = useRef(null);
  const chartRef = useRef(null);

  // Convert USDC symbol to USD
  const usdSymbol = symbol.replace("-USDC", "-USD");

  const connectWebSocket = useCallback(() => {
    if (ws.current) {
      ws.current.close();
    }

    ws.current = new WebSocket("wss://ws-feed.exchange.coinbase.com");

    ws.current.onopen = () => {
      console.log("WebSocket Connected");
      const subscribeMessage = {
        type: "subscribe",
        product_ids: [usdSymbol],
        channels: ["ticker"],
      };
      ws.current.send(JSON.stringify(subscribeMessage));
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "ticker" && data.product_id === usdSymbol) {
        const price = parseFloat(data.price);
        const timestamp = new Date(data.time).getTime();

        setCurrentPrice(price);
        setVolume24h(data.volume_24h);
        setLow24h(data.low_24h);
        setHigh24h(data.high_24h);
        setPriceChange24h(data.price_percent_chg_24h);

        setPriceData((prevData) => {
          const newData = [...prevData, { x: timestamp, y: price }];
          if (newData.length > 100) newData.shift();
          return newData;
        });
      }
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.current.onclose = () => {
      console.log("WebSocket Disconnected");
      // Attempt to reconnect after a short delay
      setTimeout(connectWebSocket, 3000);
    };
  }, [usdSymbol]);

  useEffect(() => {
    setPriceData([]);
    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [usdSymbol, connectWebSocket]);

  const chartData = {
    datasets: [
      {
        label: `${symbol} Price`,
        data: priceData,
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        fill: false,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        time: { unit: "second" },
        adapters: { date: { locale: enUS } },
        ticks: { maxTicksLimit: 8 },
      },
      y: {
        beginAtZero: false,
        title: { display: true, text: "Price (USDC)" },
      },
    },
    animation: { duration: 0 },
    plugins: {
      legend: { display: false },
      title: { display: true, text: `${symbol} Live Price` },
    },
  };

  return (
    <div>
      <div style={{ height: "400px" }}>
        <Line ref={chartRef} data={chartData} options={options} />
      </div>
      <div className="mt-4">
        <p>
          Current Price:{" "}
          {currentPrice ? `$${currentPrice.toFixed(4)}` : "Loading..."}
        </p>
        <p>24h Volume: {volume24h || "Loading..."}</p>
        <p>24h Low: {low24h || "Loading..."}</p>
        <p>24h High: {high24h || "Loading..."}</p>
        <p>
          24h Price Change:{" "}
          {priceChange24h ? `${priceChange24h}%` : "Loading..."}
        </p>
      </div>
    </div>
  );
};

export default LiveChart;
