import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Card from "./card";
import LiveChart from "./LiveChart";

const Dashboard = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [usdcProducts, setUsdcProducts] = useState([]);
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get("http://localhost:8000/api/products/");
      console.log("API response:", response.data);

      let fetchedProducts = response.data.products;

      if (!Array.isArray(fetchedProducts)) {
        throw new Error("Products data is not in the expected format");
      }

      setAllProducts(fetchedProducts);

      // Filter for USDC pairs
      const usdcPairs = fetchedProducts.filter(
        (product) =>
          product.quote_currency === "USDC" ||
          product.product_id.endsWith("-USDC")
      );

      setUsdcProducts(usdcPairs);

      if (usdcPairs.length > 0) {
        setSelectedSymbol(usdcPairs[0].product_id);
      } else {
        setError("No USDC trading pairs available.");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      setError(`Failed to fetch products: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleSymbolChange = useCallback((event) => {
    setSelectedSymbol(event.target.value);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">
          Crypto Dashboard (USDC Pairs)
        </h1>

        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {usdcProducts.length > 0 && (
          <div className="mb-8">
            <Card title="Live Chart">
              <div className="mb-4">
                <label
                  htmlFor="symbol-select"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Select USDC Trading Pair:
                </label>
                <select
                  id="symbol-select"
                  value={selectedSymbol}
                  onChange={handleSymbolChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  {usdcProducts.map((product) => (
                    <option key={product.product_id} value={product.product_id}>
                      {product.base_currency ||
                        product.product_id.split("-")[0]}
                      /USDC
                    </option>
                  ))}
                </select>
              </div>
              {selectedSymbol && (
                <LiveChart key={selectedSymbol} symbol={selectedSymbol} />
              )}
            </Card>
          </div>
        )}

        <Card title="Available USDC Trading Pairs">
          {loading ? (
            <p>Loading...</p>
          ) : usdcProducts.length > 0 ? (
            <div className="max-h-60 overflow-y-auto">
              <ul className="space-y-2">
                {usdcProducts.map((product) => (
                  <li key={product.product_id} className="text-gray-700">
                    <strong>
                      {product.base_currency ||
                        product.product_id.split("-")[0]}
                      /USDC:
                    </strong>{" "}
                    {product.product_id}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p>No USDC trading pairs available.</p>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
