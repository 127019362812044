import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PnLDisplay = () => {
  const token = localStorage.getItem("coinbase_token"); // Or however you manage the token
  const navigate = useNavigate();
  const [pnl, setPnL] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      axios
        .get("/api/calculate-pnl/", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setPnL(response.data.pnl);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    }
  }, [token, navigate]);

  if (!token) return null;
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Your Portfolio PnL</h2>
      <p>{pnl}</p>
    </div>
  );
};

export default PnLDisplay;
