import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ConnectCoinbase = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const connectToCoinbase = async () => {
      try {
        // Simulate the OAuth connection process
        const response = await axios.get("http://localhost:4000/auth/coinbase");
        if (response.status === 200) {
          // Successfully connected to Coinbase
          const exchanges =
            JSON.parse(localStorage.getItem("connectedExchanges")) || [];
          exchanges.push("coinbase");
          localStorage.setItem("connectedExchanges", JSON.stringify(exchanges));

          // Redirect to the dashboard
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error connecting to Coinbase:", error);
        // Handle error (e.g., display error message)
      }
    };

    connectToCoinbase();
  }, [navigate]);

  return <div>Connecting to Coinbase...</div>;
};

export default ConnectCoinbase;
