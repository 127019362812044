import React, { useMemo } from "react";
import useCoinbaseOAuth from "./useCoinbaseOAuth";
import ErrorBoundary from "./ErrorBoundary";
import {
  COINBASE_CLIENT_ID,
  COINBASE_CLIENT_SECRET,
  COINBASE_REDIRECT_URI,
  COINBASE_SCOPE,
} from "./config";

const CoinbaseAuthContent = () => {
  const coinbaseConfig = useMemo(
    () => ({
      clientId: COINBASE_CLIENT_ID,
      clientSecret: COINBASE_CLIENT_SECRET,
      redirectUri: COINBASE_REDIRECT_URI,
      scope: COINBASE_SCOPE,
    }),
    []
  );

  const { token, error, loading, authorize, logout } =
    useCoinbaseOAuth(coinbaseConfig);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {token ? (
        <>
          <p>You are logged in to Coinbase!</p>
          <button onClick={logout}>Logout</button>
        </>
      ) : (
        <button onClick={authorize}>Login with Coinbase</button>
      )}
    </div>
  );
};

const CoinbaseAuthComponent = () => (
  <ErrorBoundary>
    <CoinbaseAuthContent />
  </ErrorBoundary>
);

export default CoinbaseAuthComponent;
